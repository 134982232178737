<!--
 * @Description: App.vue
 * @Date: 2022-03-21 12:16:32
 * @LastEditTime: 2022-03-21 12:21:49
-->
<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    this.$aegis.reportEvent({
      name: 'loaded',
      ext1: 'loaded-success',
      ext2: 'webrtcQuickDemoVue2',
    });
  },
};
</script>

<style lang="scss">
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}
* {
  box-sizing: border-box;
}
body {
    background: url("https://cuiya.oss-cn-beijing.aliyuncs.com/sys/sysStyleBackLinkTheme/20210412143502745.jpg") no-repeat top center;
    background-size: 100% 100%;
    background-attachment: fixed;
}
#app {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
}

.flex{
  display: flex;
}

.a-center{
  align-items: center;
}
</style>
