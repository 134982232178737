import Vue from 'vue';
import VueRouter from 'vue-router';
const test = () => import('../views/test.vue')
const findPwd = () => import('../views/findPwd.vue')
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: test
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "invite" */ '../views/Home.vue'),
  },
  {
    path: '/findPwd',
    name: 'findPwd',
    component: findPwd,
  }
];

const router = new VueRouter({
  mode:'history',
  base:'/',
  routes
});

export default router;
